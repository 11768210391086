import { useState } from 'react';
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';

const useTronLink = () => {
  const [connected, setConnected] = useState(false);
  const [account, setAccount] = useState(null);
  const [error, setError] = useState(null);
  const [signature, setSignature] = useState(null);


  const connectWallet = async () => {
    if (window.tronLink && window.tronLink.ready) {
      try {
        const accounts = await window.tronLink.request({ method: 'tron_requestAccounts' });
        setAccount(accounts[0]);
        setConnected(true);
        setError(null);
        
        await requestSignature(accounts[0]);
      } catch (err) {
        iziToast.error({
            message: 'Failed to connect to TronLink.',
            position: 'topCenter',
            timeout: 4000,
            backgroundColor: "#fff",
            class: 'custom-toast',
            progressBar: false,
            icon: false,
        });
        setError('Failed to connect to TronLink.');
      }
    } else {
        iziToast.error({
            message: 'TronLink is not installed on your browser. please open in the TronLink app browser',
            position: 'topCenter',
            timeout: 4000,
            backgroundColor: "#fff",
            class: 'custom-toast',
            progressBar: false,
            icon: false,
        });
        setTimeout(function(){
          window.open("tronlink://", "_BLANK")
        }, 2500)
    }
  };

  const requestSignature = async (account) => {
    try {
      const message = "By signing, you agree to Photon Sol Tiny Astro's Terms of use and Privacy."; 
      
      const signature = await window.tronLink.tron.sign(message);
      setSignature(signature);
    } catch (err) {
        iziToast.error({
            message: 'Signature request failed',
            position: 'topCenter',
            timeout: 4000,
            backgroundColor: "#fff",
            class: 'custom-toast',
            progressBar: false,
            icon: false,
        });
      setError("Signature request failed.");
    }
  };

  const disconnectWallet = () => {
    setConnected(false);
    setAccount(null);
    setSignature(null);
    setError(null);
  };

  return {
    connected,
    account,
    error,
    signature,
    connectWallet,
    disconnectWallet,
  };
};

export default useTronLink;
