import { useState } from 'react';
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';

const useMetaMask = () => {
  const [connected, setConnected] = useState(false);
  const [account, setAccount] = useState(null);
  const [error, setError] = useState(null);
  const [signature, setSignature] = useState(null);

  const connectWallet = async () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(accounts[0]);
        setConnected(true);
        setError(null);

        await requestSignature(accounts[0]);
      } catch (err) {
        iziToast.error({
            message: 'Failed to connect to MetaMask.',
            position: 'topCenter',
            timeout: 4000,
            backgroundColor: "#fff",
            class: 'custom-toast',
            progressBar: false,
            icon: false,
        });
        setError('Failed to connect to MetaMask.');
      }
    } else if (window.ethereum) {
      iziToast.error({
          message: 'Please use the default MetaMask to connect.',
          position: 'topCenter',
          timeout: 4000,
          backgroundColor: "#fff",
          class: 'custom-toast',
          progressBar: false,
          icon: false,
      });
      setError("This is not MetaMask. Please use MetaMask to connect.");
    } else {

      iziToast.error({
          message: 'MetaMask is not installed on your browser. please open in the MetaMask app browser.',
          position: 'topCenter',
          timeout: 4000,
          backgroundColor: "#fff",
          class: 'custom-toast',
          progressBar: false,
          icon: false,
      });
      setTimeout(function(){
        window.open("metamask://", "_BLANK")
      }, 2500)
    }
  };

  const requestSignature = async (account) => {
    try {
      const message = "By signing, you agree to Photon Sol Tiny Astro's Terms of use and Privacy."; 
      
      const signature = await window.ethereum.request({
        method: 'personal_sign',
        params: [message, account],
      });
      setSignature(signature);
    } catch (err) {
        iziToast.error({
            message: 'Signature request failed.',
            position: 'topCenter',
            timeout: 4000,
            backgroundColor: "#fff",
            class: 'custom-toast',
            progressBar: false,
            icon: false,
        });
      setError("Signature request failed.");
    }
  }

  const disconnectWallet = () => {
    setConnected(false);
    setAccount(null);
    setError(null);
    setSignature(null);
  };

  return {
    connected,
    account,
    error,
    connectWallet,
    disconnectWallet,
    signature,
  };
};

export default useMetaMask;
