import { useState, useEffect } from 'react';

const MOBILE_WIDTH = 768;

const useScreenType = () => {
  const [screenType, setScreenType] = useState('');

  useEffect(() => {
    const detectScreenType = () => {
      if (window.innerWidth <= MOBILE_WIDTH) {
        setScreenType('Mobile');
      } else {
        setScreenType('Desktop');
      }
    };

    detectScreenType(); 
    
    window.addEventListener('resize', detectScreenType);
    
    return () => {
      window.removeEventListener('resize', detectScreenType);
    };
  }, []); 

  return screenType;
};

export default useScreenType;
