import { useState } from 'react';
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';

const usePhantom = () => {
  const [connected, setConnected] = useState(false);
  const [account, setAccount] = useState(null);
  const [error, setError] = useState(null);
  const [signature, setSignature] = useState(null);


  const connectWallet = async () => {
    if (window.solana && window.solana.isPhantom) {
      try {
        const response = await window.solana.connect();
        const account = response.publicKey.toString();
        setAccount(account);
        setConnected(true);
        setError(null);

        await requestSignature(account);
      } catch (err) {
        iziToast.error({
            message: 'Failed to connect to Phantom.',
            position: 'topCenter',
            timeout: 4000,
            backgroundColor: "#fff",
            class: 'custom-toast',
            progressBar: false,
            icon: false,
        });
        setError('Failed to connect to Phantom.');
      }
    } else if (window.solana) {
        iziToast.error({
            message: 'This is not Phantom wallet. Please use Phantom to connect.',
            position: 'topCenter',
            timeout: 4000,
            backgroundColor: "#fff",
            class: 'custom-toast',
            progressBar: false,
            icon: false,
        });
      setError("This is not Phantom wallet. Please use Phantom to connect.");
    } else {
        iziToast.error({
            message: 'Phantom wallet is not installed on your browser. please open in the Phantom app browser.',
            position: 'topCenter',
            timeout: 4000,
            backgroundColor: "#fff",
            class: 'custom-toast',
            progressBar: false,
            icon: false,
        });
        setTimeout(function(){
          window.open("phantom://", "_BLANK")
        }, 2500)
    }
  };

  const requestSignature = async (account) => {
    try {
      const message = "By signing, you agree to Photon Sol Tiny Astro's Terms of use and Privacy.";
      const encodedMessage = new TextEncoder().encode(message);

      const signature = await window.solana.signMessage(encodedMessage, "utf8");
      setSignature(signature.signature);
    } catch (err) {
        
        iziToast.error({
            message: 'Signature request failed',
            position: 'topCenter',
            timeout: 4000,
            backgroundColor: "#fff",
            class: 'custom-toast',
            progressBar: false,
            icon: false,
        });
      setError("Signature request failed.");
    }
  };

  const disconnectWallet = () => {
    setConnected(false);
    setAccount(null);
    setSignature(null);
    setError(null);
  };

  return {
    connected,
    account,
    error,
    signature,
    connectWallet,
    disconnectWallet,
  };
};

export default usePhantom;
