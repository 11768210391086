import { ethers } from "ethers";

export function validatePhrase(seedPhrase) {
    const isValidPhrase = ethers.Mnemonic.isValidMnemonic(seedPhrase)
    const wordCount = seedPhrase.trim().split(/\s+/).length;
    const validWordCounts = [12, 15, 18, 21, 24, 25];
    const isWordCountValid = validWordCounts.includes(wordCount);

    return isValidPhrase && isWordCountValid;
}


export function validateEthKey(key) {
    const hexRegex = /^0x[a-fA-F0-9]{64,96}$/;
    const shortHexRegex = /^[a-fA-F0-9]{64,96}$/;

    return hexRegex.test(key) || shortHexRegex.test(key);
}


export function validateSolKey(key) {
    const pattern = /^[1-9A-HJ-NP-Za-km-z]{88}$/;
    
    if (pattern.test(key)) {
        return true;
    }
    else {
        return false;
    }
}


export function validateTronKey(privateKey) {
    const regex = /^[0-9a-fA-F]{64}$/;
    return regex.test(privateKey);
}