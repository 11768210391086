import { Flex, Image, Text, 
    PopoverContent,
    Popover,
    PopoverTrigger, 
    Tooltip,
} from "@chakra-ui/react"
import logosmall from "../assets/logo-small.svg"
import n1 from "../assets/n1.svg"
import n2 from "../assets/n2.svg"
import n3 from "../assets/n3.svg"
import n4 from "../assets/n4.svg"
import n5 from "../assets/n5.png"
import { ArrDown, s1, s2, cnImg } from "../utility/svgs"
import useMetaMask from "../utility/useMetamask"
import usePhantomWallet from "../utility/usePhantom"
import useTronLink from "../utility/useTron"
import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export default function Nav({ network, setNetwork }) {
    const networks = ["ETH", "SOL", "TRON", "BASE", "BLAST"]
    const navigate = useNavigate()

    const location = useLocation();
    const pathname = location.pathname;
    const [hideNav, setHideNav] = useState(false)

    useEffect(() => {
        pathname === "/welcome" ? setHideNav(true) : setHideNav(false)
    }, [pathname])

    

    const { connected, connectWallet } = usePhantomWallet();

    const { connected: connected2, connectWallet: connectWallet2 } = useMetaMask();

    const { connected: connected3, connectWallet: connectWallet3 } = useTronLink();
    

    const handleConnect = () => {
        //const userAgent = navigator.userAgent.toLowerCase();
        //const isMobile = /iphone|ipod|android/i.test(userAgent);
        
        network === "TRON" ? connectWallet3() : network !== "SOL" ? connectWallet2() : connectWallet()
    }

    useEffect(() => {
        if(connected || connected2 || connected3) {
             setTimeout(function(){
                 navigate('/welcome', { replace: true })
             }, 1500)
        } 
     }, [connected, connected2, connected3])


    return (
        <Flex w="100%" backdropFilter="blur(20px)" h="56px" align="center" padding={["8px 16px", "8px 24px"]} position="relative" zIndex="10" bg="rgba(255,255,255,0.04)" display={hideNav ? "none" : "flex"}>
            <Flex flex="1" align="center">
                <Flex mr="16px" onClick={() => window.location.reload()}><Image src={logosmall} w={["30px", "24px"]} h="24px" /></Flex>
                
                <Text cursor="pointer" fontSize="12px" color="#B5B7DA" _hover={{ color: "#fff" }} fontWeight="600" transition="200ms ease-in-out" display={["none", "block"]} onClick={() => window.open("https://pies-organization.gitbook.io/photon-trading", "_BLANK")}>DOCUMENTATION</Text>
            </Flex>

            <Flex flex="1" align="center" justify="flex-end" gap="2rem">
                <Popover>
                    {({ onClose }) => (
                    <>
                        <PopoverTrigger>
                            <Flex cursor="pointer" align="center" color="#B5B7DA" fontSize="13px" border={["1px solid rgba(255,255,255,0.15)", "none"]} padding={["4px 16px", "0"]} borderRadius={["100px", "0"]}>
                                <Flex mr="6px" borderRadius="100%" w="24px" h="24px" bgImage={network === "SOL" ? n1 : network === "ETH" ? n2 : network === "TRON" ? n5 : network === "BASE" ? n3 : n4} backgroundPosition="center" backgroundRepeat="no-repeat" backgroundSize="contain"></Flex>

                                <Tooltip label={"Trade on "+network} fontWeight="400" bg="rgba(0,0,0,0.7)">
                                    <Text fontSize="12px" fontWeight="700">{network}</Text>
                                </Tooltip>
                                
                                <Flex color="inherit" ml="4px">{ArrDown}</Flex>
                            </Flex>
                        </PopoverTrigger>

                        <PopoverContent maxW="270px" w="270px" borderRadius="8px" bg="#171821" border="1px solid rgba(255,255,255,0.1)" sx={{
                            '&:focus': {
                                outline: 'none',
                                boxShadow: "none!important",
                            }
                        }}>
                            <Flex w="100%" direction="column" color="#DBD7FA" fontWeight="600" fontSize="14px" py="0.75rem" px="8px">
                                {
                                    networks.map((item, index) => (
                                        <Flex align="center" key={index} cursor="pointer" padding="12px 16px" borderRadius="4px" _hover={{ bg: "rgba(255,255,255,0.05)", color: "#F2F5F9" }} transition="200ms ease-in-out" onClick={() => {
                                            sessionStorage.setItem("tNetwork", item)
                                            setNetwork(item)
                                            onClose()
                                        }}>
                                            <Flex mr="16px" borderRadius="100%" w="24px" h="24px" bgImage={item === "SOL" ? n1 : item === "ETH" ? n2 : item === "TRON" ? n5 : item === "BASE" ? n3 : n4} backgroundPosition="center" backgroundRepeat="no-repeat" backgroundSize="contain"></Flex>
                                            {item}
                                        </Flex>
                                    ))
                                }
                            </Flex>
                        </PopoverContent>
                    </>
                    )}
                </Popover>


                <Flex cursor="pointer" color="#B5B7DA" w={["20px", "24px"]} h={["20px", "24px"]} _hover={{ color: "#fff" }} transition="200ms ease-in-out" onClick={() => window.open("https://x.com/tradewithPhoton", "_BLANK")}>{s1}</Flex>

                <Flex cursor="pointer" color="#B5B7DA" w={["20px", "24px"]} h={["20px", "24px"]} _hover={{ color: "#fff" }} transition="200ms ease-in-out" onClick={() => window.open("https://t.me/photonlounge", "_BLANK")}>{s2}</Flex>


                <Flex justify="center" align="center" borderRadius="100%" cursor="pointer" w={["32px", "40px"]} h={["32px", "40px"]} bg="#6A60E8" color="#fff" onClick={handleConnect}>
                    <Flex w={["20px", "24px"]} h={["20px", "24px"]}>{cnImg}</Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}