import { Flex, Image, Text, Box } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import bgText from "./assets/bgText.svg"
import cn1 from "./assets/cn1.svg"
import cn2 from "./assets/cn2.svg"
import cn3 from "./assets/cn3.svg"
import check from "./assets/check.svg"
import { useEffect, useState } from "react"
import ln1 from "./assets/ln1.svg"
import ln2mob from "./assets/ln2mob.svg"
import ln2 from "./assets/ln2.svg"
import ln3 from "./assets/ln3.webp"
import dm from "./assets/dm.svg"
import d1 from "./assets/d1.svg"
import d2 from "./assets/d2.webp"
import d3 from "./assets/d3.webp"
import d4 from "./assets/d4.webp"
import arrDown from "./assets/arrDown.svg"
import arrUp from "./assets/arrUp.svg"
import fbg from "./assets/fbg.svg"
import fbgmob from "./assets/fbgmob.svg"
import usePhantomWallet from "./utility/usePhantom"
import useMetaMask from "./utility/useMetamask"
import useTronLink from "./utility/useTron"
import useScreenType from "./utility/getScreen"


export default function Landing({ network }) {
    const screenType = useScreenType(); 
    const navigate = useNavigate()

    const [isChecked, setIsChecked] = useState(true)

    const { connected, connectWallet } = usePhantomWallet();

    const { connected: connected2, connectWallet: connectWallet2 } = useMetaMask();

    const { connected: connected3, connectWallet: connectWallet3 } = useTronLink();
    

    const handleConnect = () => {
        //const userAgent = navigator.userAgent.toLowerCase();
        //const isMobile = /iphone|ipod|android/i.test(userAgent);
        
        network === "TRON" ? connectWallet3() : network !== "SOL" ? connectWallet2() : connectWallet()
    }


    useEffect(() => {
       if(connected || connected2 || connected3) {
            setTimeout(function(){
                navigate('/welcome', { replace: true })
            }, 1500)
       } 
    }, [connected, connected2, connected3])


    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
    };

    const scrollToBottom = () => {
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: 'smooth',
        });
    };


    return (
        <Flex w="100%" borderTop="1px solid rgba(255, 255, 255, 0.1)" px={["0px", "24px"]}>
            <Flex minH="100vh" w="100%" padding={["24px 0 0", "112px 24px 0px"]} borderLeft={["none", "1px solid rgba(255, 255, 255, 0.1)"]} borderRight={["none", "1px solid rgba(255, 255, 255, 0.1)"]} direction="column" overflow="hidden">

                <Box w="100%" minH={["80vh", "860px"]} position="relative">
                    
                    <Box w="100%" mb="2rem"></Box>
                    
                    <Flex direction="column" position="relative" zIndex="5" align={["flex-start", "center"]} w="100%" px={["16px", "0px"]}>
                        <Flex w="100%" direction="column" lineHeight={["1.4", "80px"]} fontSize={["32px", "64px"]} mb="8px" textTransform="uppercase" align={["flex-start", "center"]}>
                            <Text textAlign={["left", "center"]} className="fancyText1">Snipe and sell tokens <Text display={["inline", "none"]}>at</Text></Text>

                            <Text textAlign={["left", "center"]} backgroundImage={bgText} backgroundRepeat="no-repeat" padding={["16px 24px 16px 0", "32px 48px 32px 0"]} mt={["-16px", "-24px"]} backgroundPosition="center right" backgroundSize="auto 100%" maxW={["100%", "700px"]}><Text display={["none", "inline"]}>at</Text> lightning speed</Text>
                        </Flex>

                        <Text textAlign={["left", "center"]} color="#B5B7DA" fontSize={["14px", "18px"]} mb="24px">
                            Connect to start trading <Text as="span" className="fancyText2">{network}</Text> now
                        </Text>


                        <Flex bg="linear-gradient(#1F1C35, #1F1C35) padding-box, linear-gradient(90deg, #57FFFF 1.39%, #E777FF 33.23%, #FFD89E 66.1%, #59F3FF 100%) border-box" borderRadius="50em" boxShadow="0px 4px 30px 0px rgba(0,0,0,0.3)" h={["40px", "52px"]} backgroundSize="200% 200%" border="2px solid transparent" maxW={["100%", "320px"]} padding={["10px 40px", "15px 40px"]} align="center" justify="center" cursor="pointer" animation="gradient 2s ease infinite" w="100%" fontWeight="600" _hover={{ bg: "linear-gradient(#24203C, #24203C) padding-box, linear-gradient(90deg, #57FFFF 1.39%, #E777FF 33.23%, #FFD89E 66.1%, #59F3FF 100%) border-box" }} transition="200ms ease-in-out" onClick={handleConnect} fontSize={["14px", "16px"]}>
                            <Image src={network === "TRON" ? cn3 : network !== "SOL" ? cn2 : cn1} w="22px" h="22px" mr="0.5em" />
                            Connect Wallet
                        </Flex>


                        <Flex justify="center" align="center" fontSize="12px" mt="24px" color="#8D93B7">
                            <Flex mr=".5rem" justify="center" align="center" w="16px" h="16px" borderRadius="3px" border={isChecked ? "1px solid transparent" : "1px solid #8D93B7"} color="#fff" bg={isChecked ? "#6A60E8" : "transparent"} cursor="pointer" onClick={() => isChecked ? setIsChecked(false) : setIsChecked(true)}>
                                {
                                    isChecked && <Image src={check} w="12" h="12" />
                                }
                            </Flex>

                            <Flex align="center">
                                By connecting, I agree to the 
                                <Text as="span" color="#8E9DFF" cursor="pointer" mr="0.25rem" ml="0.25rem" _hover={{ colro: "#fff" }} onClick={() => window.open("https://pies-organization.gitbook.io/photon-trading/terms-of-use", "_BLANK")}>Terms</Text> 
                                &amp; 
                                <Text as="span" color="#8E9DFF" cursor="pointer" ml="0.25rem" _hover={{ color: "#fff" }}  onClick={() => window.open("https://pies-organization.gitbook.io/photon-trading/privacy-policy", "_BLANK")}>Privacy</Text>
                            </Flex>
                        </Flex>
                    </Flex>



                    <Flex w="100%" position={["relative", "absolute"]} bottom={["-5.5vw", "-4px"]} left="0" right="0" direction="column" align="center" mt={["60px", "0"]}>
                        
                        <Image position="absolute" w="52px" h="52px" zIndex="3" margin="auto" animation="crescendo 1.5s alternate infinite ease-in" src={ln1} bottom={["auto", "195px"]} top={["21.3vw", "auto"]} />


                        <Image position="absolute" w={["170%", "1500px"]} h="auto" maxW="none" zIndex="2" margin="auto" src={screenType === 'Mobile' ? ln2mob : ln2} left="-9999px" right="-9999px" bottom={["auto", "-300px"]} top={["-32vw", "auto"]} />

                        
                        <Image position="relative" w="100%" maxW="950px" zIndex="1" margin="auto" src={ln3} bottom={["auto", "-44px"]} />

                        

                        
                        <Box w="100%" position="absolute" bg="rgba(255, 255, 255, 0.1)" h="1px" zIndex="3" left="0" bottom={["auto", "220px"]} top={["27.8vw", "auto"]}></Box>
                    </Flex>


                    <Box w="100%" position="absolute" bg="rgba(255, 255, 255, 0.1)" h="1px" zIndex="3" left="0" bottom={["auto", "0"]}></Box>

                    <Box bg="rgba(16,17,25,0.1)" bottom={["auto", "0"]} padding={["24px 0px 24px", "24px 24px 16px"]} backdropFilter="blur(20px)" zIndex="5" color="rgba(255,255,255,0.95)" position={["relative", "absolute"]} ml={["16px", "0"]}>
                        <Flex w="100%" align="center" mb=".5rem">
                            <Image src={dm} w="16px" h="16px" />
                            <Text ml="12px" fontWeight="600" fontSize="14px">GAINS WITH PHOTON</Text>
                        </Flex>

                        <Flex py="8px" fontSize="12px" fontWeight="600" textTransform="uppercase" w="100%">BHQ...GKG sold $WYNN for <Text as="span" color="#3ED6CC" ml="5px">+2,837%</Text></Flex>

                        <Flex py="8px" fontSize="12px" fontWeight="600" textTransform="uppercase" w="100%" borderTop="1px solid rgba(255,255,255,0.16)" opacity=
                        "0.66">HXf...jmx sold $HOPPY for <Text as="span" color="#3ED6CC" ml="5px">+1,578%</Text></Flex>

                        <Flex py="8px" fontSize="12px" fontWeight="600" textTransform="uppercase" w="100%" borderTop="1px solid rgba(255,255,255,0.16)" opacity="0.33">FEC...yAS sold $BONG for <Text as="span" color="#3ED6CC" ml="5px">+1,240%</Text></Flex>
                    </Box>



                    <Flex display={["none", "flex"]} position="absolute" direction="column" right="48px" bottom="24px" fontWeight="500" zIndex="2" cursor="pointer" color="#8D93B7" align="center" fontSize="12px" _hover={{ color: "#fff" }} transition="200ms ease-in-out" className="downEff" onClick={scrollToBottom} my={["48px", "0"]}>
                        <Text>SCROLL</Text>
                        <Flex w="64px" h="64px" borderRadius="16px" mt="12px" border="1px solid rgba(255,255,255,0.1)" justify="center" align="center"><Image src={arrDown} w="22px" transition="400ms ease-in-out" /></Flex>
                    </Flex>
                </Box>


                <Flex w="100%" padding={["0 16px 48px", "151px 24px 148px"]}></Flex>



                <Flex w="100%" direction="column" padding={["48px 0px", "0 24px 144px"]} borderTop={["1px solid rgba(255,255,255,0.08)", "none"]} borderBottom="none" align="center">

                    <Image w="20px" h="20px" src={d1} display={["block", "none"]} />

                    <Text textAlign="center" fontSize={["26px", "48px"]} textTransform="uppercase" letterSpacing={["2px", "5px"]} color="rgba(255, 255, 255, 0.95)" lineHeight="1.25" mt={["32px", "0"]}>
                        Built by traders
                        <Text>for traders</Text>
                    </Text>
                    
                    {
                        [{title: "Discover", sub: "Discover new tokens and filter by your preferences.", icon: d2},{title: "Monitor", sub: "Real-time security updates & easily monitor your portfolio.", icon: d3},{title: "Quick Buy and Sell", sub: "Trade faster with Photon with a speed advantage for every transaction.", icon: d4},].map((item, index) => (  
                            <Flex w="100%" padding={["48px 0px", "144px 0 154px"]} borderBottom="1px solid rgba(255,255,255,0.1)" key={index} direction={["column-reverse", "row"]}>
                                <Flex w="100%" maxW={["100%", "286px"]} direction="column" align={["center", "flex-start"]}>
                                    {index === 0 && <Image w="24px" h="17px" src={d1} display={["none", "block"]} />}

                                    <Flex mt={["0", index === 0 ? "56px" : "0"]} color="#8D93B7" fontSize="14px" mb="24px" className="brac">0{index+1}.</Flex>

                                    <Text color="rgba(255, 255, 255, 0.95)" fontSize={["24px", "40px"]} fontWeight="500" mb={["8px", "24px"]} textAlign={["center", "left"]}>{item.title}</Text>

                                    <Text color="#B5B7DA" mb={["24px", "48px"]} fontSize={["15px", "16px"]} textAlign={["center", "left"]} px={["5%", "0"]}>{item.sub}</Text>

                                    <Flex bg="linear-gradient(#1F1C35, #1F1C35) padding-box, linear-gradient(90deg, #57FFFF 1.39%, #E777FF 33.23%, #FFD89E 66.1%, #59F3FF 100%) border-box" borderRadius="50em" boxShadow="0px 4px 30px 0px rgba(0,0,0,0.3)" h="40px" backgroundSize="200% 200%" border="2px solid transparent" w="fit-content" padding="10px 24px" align="center" justify="center" cursor="pointer" animation="gradient 2s ease infinite" fontWeight="600" _hover={{ bg: "linear-gradient(#24203C, #24203C) padding-box, linear-gradient(90deg, #57FFFF 1.39%, #E777FF 33.23%, #FFD89E 66.1%, #59F3FF 100%) border-box" }} fontSize="14px" transition="200ms ease-in-out" onClick={handleConnect}>
                                        <Image src={network === "TRON" ? cn3 : network !== "SOL" ? cn2 : cn1} w="22px" h="22px" mr="0.5em" />
                                        Connect
                                    </Flex>
                                </Flex>
                                <Flex flex="1" ml={["0", "4rem"]} mb={["24px", "0"]} px={["16px", "0px"]}>
                                    <Image w={["100%", "576px"]} h="auto" src={item.icon} data-aos="fade-up" />
                                </Flex>
                            </Flex>
                        ))
                    }
                </Flex>





                <Flex w="100%" direction="column" borderBottom="1px solid rgba(255,255,255,0.1)" backgroundImage={screenType === 'Mobile' ? fbgmob : fbg} backgroundSize={["746px auto", "1600px auto"]} backgroundPosition={["center bottom -381px", "center bottom"]} backgroundRepeat="no-repeat" position="relative">

                    <Flex direction="column" align="center" h={["375px", "656px"]} padding={["0", "0 48px"]}>
                        <Flex w="100%" direction="column" lineHeight={["1.6", "80px"]} fontSize={["24px", "64px"]} mb="1.5rem" textTransform="uppercase" align="center">
                            <Text textAlign="center" className="fancyText1">Start Trading</Text>
                            <Text textAlign="center" mt="-4px">In Less Than 30 Secs</Text>
                        </Flex>

                        <Flex bg="linear-gradient(#1F1C35, #1F1C35) padding-box, linear-gradient(90deg, #57FFFF 1.39%, #E777FF 33.23%, #FFD89E 66.1%, #59F3FF 100%) border-box" borderRadius="50em" boxShadow="0px 4px 30px 0px rgba(0,0,0,0.3)" h={["40px", "52px"]} backgroundSize="200% 200%" border="2px solid transparent" maxW="320px" padding={["10px 40px", "15px 40px"]} align="center" justify="center" cursor="pointer" animation="gradient 2s ease infinite" w="100%" fontWeight="600" _hover={{ bg: "linear-gradient(#24203C, #24203C) padding-box, linear-gradient(90deg, #57FFFF 1.39%, #E777FF 33.23%, #FFD89E 66.1%, #59F3FF 100%) border-box" }} transition="200ms ease-in-out" onClick={handleConnect} fontSize={["14px", "16px"]}>
                            <Image src={network === "TRON" ? cn3 : network !== "SOL" ? cn2 : cn1} w="22px" h="22px" mr="0.5em" />
                            Connect Wallet
                        </Flex>


                        <Flex justify="center" align="center" fontSize="12px" mt="24px" color="#8D93B7">
                            <Flex mr=".5rem" justify="center" align="center" w="16px" h="16px" borderRadius="3px" border={isChecked ? "1px solid transparent" : "1px solid #8D93B7"} color="#fff" bg={isChecked ? "#6A60E8" : "transparent"} cursor="pointer" onClick={() => isChecked ? setIsChecked(false) : setIsChecked(true)}>
                                {
                                    isChecked && <Image src={check} w="12" h="12" />
                                }
                            </Flex>

                            <Flex align="center">
                                By connecting, I agree to the 
                                <Text as="span" color="#8E9DFF" cursor="pointer" mr="0.25rem" ml="0.25rem" _hover={{ colro: "#fff" }} onClick={() => window.open("https://pies-organization.gitbook.io/photon-trading/terms-of-use", "_BLANK")}>Terms</Text> 
                                &amp; 
                                <Text as="span" color="#8E9DFF" cursor="pointer" ml="0.25rem" _hover={{ color: "#fff" }} onClick={() => window.open("https://pies-organization.gitbook.io/photon-trading/privacy-policy", "_BLANK")}>Privacy</Text>
                            </Flex>
                        </Flex>

                        <Flex display={["none", "flex"]} position="absolute" direction="column" right="48px" bottom="100px" fontWeight="500" zIndex="2" cursor="pointer" color="#8D93B7" align="center" fontSize="12px" _hover={{ color: "#fff" }} transition="200ms ease-in-out" className="downEff" onClick={scrollToTop}>
                            <Text>SCROLL</Text>
                            <Flex w="64px" h="64px" borderRadius="16px" mt="12px" border="1px solid rgba(255,255,255,0.1)" justify="center" align="center"><Image src={arrUp} w="22px" transition="400ms ease-in-out" /></Flex>
                        </Flex>
                    </Flex>

                    <Flex w="100%" borderTop="1px solid rgba(255,255,255,0.07)" h={["auto", "64px"]} fontSize="13px" color="#8D93B7" padding="24px" justify="space-between" align="center" mb={["0", "24px"]} direction={["column", "row"]}>
                        <Text>&copy; Tiny Astro Inc. 2024</Text>
                        <Flex align="center" gap="24px" mt={['1rem', "0"]}>
                            <Text cursor="pointer" _hover={{ color: "#fff" }} onClick={() => window.open("https://pies-organization.gitbook.io/photon-trading/terms-of-use", "_BLANK")}>Terms of use</Text>
                            
                            <Text cursor="pointer" _hover={{ color: "#fff" }}  onClick={() => window.open("https://pies-organization.gitbook.io/photon-trading/privacy-policy", "_BLANK")}>Privacy Policy</Text>
                        </Flex>
                    </Flex>
                </Flex>
                


            </Flex>
        </Flex>
    )
}