import './App.css';
import { ChakraProvider, Flex } from "@chakra-ui/react";
import Landing from './landing';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useLayoutEffect, useState } from 'react';
import Nav from './components/nav';
import Signin from './signin';


export const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
  }, [location.pathname]);
  return children
}


function App() {
  const getNetwork = sessionStorage.getItem("tNetwork")
  const [network, setNetwork] = useState((getNetwork !== undefined && getNetwork !== null) ? getNetwork : "SOL")

  return (
    <ChakraProvider>
      <Flex w="100%" minH="100vh" direction="column" bg="#0f1018">
        <Flex w="100%" minH="100vh" direction="column" fontSize="16px" bg="linear-gradient(165deg, rgba(118, 120, 136, 0.1) 3.89%, rgba(118, 120, 136, 0) 66.42%) 0% 0% / 100% 890px no-repeat" color="#fff">
          <BrowserRouter>
            <Wrapper>
              <Nav network={network} setNetwork={setNetwork} />
              <Routes>
                <Route path="/" element={<Landing network={network} />} />
                <Route path='/welcome' element={<Signin network={network} />} />
              </Routes>
            </Wrapper>
          </BrowserRouter>
        </Flex>
      </Flex>
    </ChakraProvider>
  );
}

export default App;
