export const ArrDown = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.2802 5.96667L8.93355 10.3133C8.42021 10.8267 7.58022 10.8267 7.06688 10.3133L2.72021 5.96667" stroke="currentColor" stroke-opacity="0.95" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


export const s1 = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.8608 10.4694L21.1424 2.00513H19.4169L13.0942 9.35455L8.04439 2.00513H2.21997L9.85635 13.1188L2.21997 21.9949H3.94558L10.6224 14.2336L15.9555 21.9949H21.7799L13.8603 10.4694H13.8608ZM11.4973 13.2167L10.7236 12.11L4.56734 3.30414H7.21777L12.1859 10.4107L12.9597 11.5174L19.4177 20.7549H16.7673L11.4973 13.2171V13.2167Z" fill="currentColor"/>
</svg>


export const s2 = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.94364 11.2904C7.58077 8.83435 11.3397 7.2152 13.2206 6.4329C18.5907 4.1993 19.7065 3.8113 20.4338 3.79848C20.5938 3.79567 20.9514 3.83531 21.1831 4.0233C21.3788 4.18204 21.4326 4.39647 21.4583 4.54697C21.4841 4.69747 21.5162 5.04032 21.4907 5.30821C21.1997 8.36585 19.9405 15.7859 19.2999 19.2105C19.0288 20.6596 18.4951 21.1455 17.9784 21.193C16.8554 21.2964 16.0027 20.4509 14.9151 19.738C13.2132 18.6223 12.2517 17.9279 10.5997 16.8392C8.69056 15.5811 9.92819 14.8896 11.0162 13.7596C11.301 13.4638 16.2486 8.96351 16.3444 8.55527C16.3564 8.50421 16.3675 8.31389 16.2544 8.21339C16.1414 8.11289 15.9745 8.14726 15.8541 8.17459C15.6834 8.21333 12.9646 10.0104 7.69761 13.5657C6.92588 14.0956 6.22687 14.3538 5.60058 14.3403C4.91015 14.3254 3.58204 13.9499 2.59473 13.629C1.38374 13.2353 0.421278 13.0272 0.505087 12.3587C0.54874 12.0105 1.02826 11.6544 1.94364 11.2904Z" fill="currentColor"/>
</svg>


export const cnImg = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.85 3.95005V7.75005H13.35V3.95005C13.35 3.68005 13.11 3.55005 12.95 3.55005C12.9 3.55005 12.85 3.56005 12.8 3.58005L4.87 6.57005C4.34 6.77005 4 7.27005 4 7.84005V8.51005C3.09 9.19005 2.5 10.28 2.5 11.51V7.84005C2.5 6.65005 3.23 5.59005 4.34 5.17005L12.28 2.17005C12.5 2.09005 12.73 2.05005 12.95 2.05005C13.95 2.05005 14.85 2.86005 14.85 3.95005Z" fill="currentColor"/>
<path d="M21.5 14.5V15.5C21.5 15.77 21.29 15.99 21.01 16H19.55C19.02 16 18.54 15.61 18.5 15.09C18.47 14.78 18.59 14.49 18.79 14.29C18.97 14.1 19.22 14 19.49 14H21C21.29 14.01 21.5 14.23 21.5 14.5Z" fill="currentColor"/>
<path d="M19.48 12.95H20.5C21.05 12.95 21.5 12.5 21.5 11.95V11.51C21.5 9.44 19.81 7.75 17.74 7.75H6.26C5.41 7.75 4.63 8.03 4 8.51C3.09 9.19 2.5 10.28 2.5 11.51V18.24C2.5 20.31 4.19 22 6.26 22H17.74C19.81 22 21.5 20.31 21.5 18.24V18.05C21.5 17.5 21.05 17.05 20.5 17.05H19.63C18.67 17.05 17.75 16.46 17.5 15.53C17.29 14.77 17.54 14.04 18.04 13.55C18.41 13.17 18.92 12.95 19.48 12.95ZM14 12.75H7C6.59 12.75 6.25 12.41 6.25 12C6.25 11.59 6.59 11.25 7 11.25H14C14.41 11.25 14.75 11.59 14.75 12C14.75 12.41 14.41 12.75 14 12.75Z" fill="currentColor"/>
</svg>




export const CopyIcon = <svg width="16" height="16" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.38683 9.76666H10.8335V12.38C10.8335 13.1506 10.5683 13.71 10.1754 14.0788C9.77763 14.4523 9.20974 14.6667 8.54683 14.6667H4.62016C3.95725 14.6667 3.38937 14.4523 2.99156 14.0788C2.59873 13.71 2.3335 13.1506 2.3335 12.38V7.95332C2.3335 7.18272 2.59873 6.62332 2.99156 6.25451C3.38937 5.88104 3.95725 5.66666 4.62016 5.66666H6.7335V7.11332C6.7335 8.58381 7.92503 9.76666 9.38683 9.76666Z" fill="currentColor" stroke="currentColor"/>
<path d="M11.0667 2.33334H12.38C13.0429 2.33334 13.6108 2.54772 14.0086 2.9212C14.4014 3.29001 14.6667 3.84941 14.6667 4.62001V5.93334V7.74001V9.05334C14.6667 9.80366 14.4141 10.3511 14.0386 10.7168C13.7673 10.9811 13.4128 11.1689 13 11.2619V10.4067V8.60001V7.28668C13 6.27061 12.6419 5.43668 12.0264 4.85882C11.4159 4.28563 10.5904 4.00001 9.71334 4.00001H8.40001H7.67334H6.23617C6.34039 3.52684 6.56364 3.16184 6.85452 2.89762C7.24931 2.53902 7.80527 2.33334 8.45334 2.33334H10.34H11.0667Z" fill="currentColor" stroke="currentColor"/>
<path d="M9.3138 8.0667L9.3138 8.06665H9.30676C8.80679 8.06665 8.42676 7.67467 8.42676 7.23332V5.91839C9.06225 6.56257 9.92913 7.43445 10.5666 8.07321C10.1211 8.0729 9.66503 8.07165 9.3138 8.0667Z" fill="currentColor" stroke="currentColor"/>
</svg>



export const RevealIcon = 
<svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.7236 12.0049C6.5915 12.0049 6.473 11.9753 6.3682 11.916C6.2679 11.8613 6.1859 11.7816 6.1221 11.6768C6.0628 11.5719 6.0332 11.4557 6.0332 11.3281C6.0332 11.1413 6.1016 10.9772 6.2383 10.8359L11.1875 6.00293V6.65234L6.2383 1.82617C6.1016 1.6849 6.0332 1.52083 6.0332 1.33398C6.0332 1.20638 6.0628 1.09245 6.1221 0.99219C6.1859 0.88737 6.2679 0.80534 6.3682 0.74609C6.473 0.68229 6.5915 0.65039 6.7236 0.65039C6.9105 0.65039 7.07 0.71647 7.2021 0.84863L12.2812 5.80469C12.3542 5.8776 12.4111 5.95964 12.4521 6.05078C12.4932 6.13737 12.5137 6.23079 12.5137 6.33105C12.5137 6.52702 12.4362 6.7002 12.2812 6.85059L7.209 11.8135C7.1406 11.8773 7.0654 11.9251 6.9834 11.957C6.9059 11.9889 6.8193 12.0049 6.7236 12.0049ZM0.9473 12.0049C0.8151 12.0049 0.696601 11.9753 0.591801 11.916C0.491501 11.8613 0.409499 11.7816 0.345699 11.6768C0.286499 11.5719 0.256802 11.4557 0.256802 11.3281C0.256802 11.1413 0.325199 10.9772 0.461899 10.8359L5.4111 6.00293V6.65234L0.461899 1.82617C0.325199 1.6849 0.256802 1.52083 0.256802 1.33398C0.256802 1.20638 0.286499 1.09245 0.345699 0.99219C0.409499 0.88737 0.491501 0.80534 0.591801 0.74609C0.696601 0.68229 0.8151 0.65039 0.9473 0.65039C1.1296 0.65039 1.2891 0.71647 1.4258 0.84863L6.5049 5.80469C6.5778 5.8776 6.6348 5.95964 6.6758 6.05078C6.7168 6.13737 6.7373 6.23079 6.7373 6.33105C6.7373 6.52702 6.6598 6.7002 6.5049 6.85059L1.4258 11.8135C1.362 11.8773 1.2891 11.9251 1.207 11.957C1.1296 11.9889 1.043 12.0049 0.9473 12.0049Z" fill="currentColor"/>
</svg>



export const CloseIcon = 
<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 6L18 18M6 18L18 6L6 18Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>




